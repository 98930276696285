import React, { FunctionComponent } from "react";

import Layout from "../../../common/layout/layout";
import SEO from "../../../common/seo";

const EldaFezziPage: FunctionComponent = () => (
  <Layout backUrl="/about/critiques" backText="Critique">
    <SEO title="Elda Fezzi" />
    <div className="lg-half-width">
      <p>
        Osservando da vicino e con una certa continuità, il lavoro di Nino
        Desirelli, si ha la netta misura di quale sia stata ed sia tuttora la
        persuasione – quasi la fascinazione – che la pittura astratta esercita
        sulla mente e sui sensi di chi la pratica. Essa offre la possibilità di
        dare concretezza ad un tipo di visione non comune; ma soprattutto
        richiede al pittore uno sforzo continuo nel rendere una giusta armonia
        ad un tipo di dipinto che si costruisce su se stesso, che ha ritmi e
        fattezze sue proprie.
      </p>
      <p>
        Presunzione di creare qualcosa ex novo? Può darsi, ma tale presunzione
        non puo’ che essere accompagnata dalla consapevolezza che, in quel campo
        nuovo da esplorare, niente deve essere gettato a caso e nessun gesto
        deve colorarsi di superficialità.
      </p>
      <p>
        Nel suo laboratorio situato in una tranquilla borgata cremonese,
        Desirelli dipinge lucidi vortici elicoidali e a tempo stesso disegna e
        costruisce aeromodelli. La stessa passione e la stessa cura artigianale
        lo assistono sia nella composizione dei complessi tracciati cromatici
        che nella costruzione di linee e congegni aerodinamici. Anzi, si
        potrebbe trovare più di una coincidenza fra i due aspetti del suo
        progettare. Qualcosa, nella forma radiante dei suoi dipinti, si accosta
        alle strutture di una massa d’aria che venga mossa, esagitata,
        proiettata e spinta dal movimento di un’elica. Verrebbe la tentazione,
        davvero, di verificare questa sequenza di “ pitture “ d’aria
        azzurro-bianca sulla base di una descrizione scientifica come quella che
        parla di “tubi di flusso” generati dal disco dell’elica: di movimenti
        generatori di energia; di vortici straordinari dell’aria provocati dal
        funzionamento di questa macchina. Essi sono rappresentabili “prima come
        un complesso di filetti fluidi non turbolenti – dice un’enciclopedia –
        paralleli alla direzione del motoe dopo come un sistema turbolento,
        altamente vorticoso. Un susseguirsi di spinte, di apparenze di velocità,
        di fasci luminosi che coinvolgono lo spazio intero della tela: tutto
        questo richiama, quasi per magia, le suggestioni di una tematica
        meccanica cara ai futuristi. Ma con una differenza: che nella pittura di
        Desirelli non è più in vista la pesantezza di elementi e di sistemi
        meccanici. Se mai, sono gli effetti grafici e ottici di qualche moto
        turbinoso, ellissoidale, che passano e filtrano in un reticolo
        equivalente, puro e arioso, definito dalla sensibilità degli strumenti e
        della materia pittorica.
      </p>
      <p>
        In apparenza, allora, queste sequenze sono frutto di un interesse
        fantastico e ludico? Può darsi, ma anche per Desirelli, come per altri
        operatori in linguaggio astratto, esiste un preciso valore etico nel
        lavoro che ci sia permesso di fare per scelta personale, e nel quale ci
        si possa esprimere con libertà.Tanto più, se in questo lavoro-gioco
        l’attenzione del pittore è talmente concentrata da diventare, oltre che
        appassionato esercizio quotidiano, anche fonte di una partecipazione
        quasi mistica alla ricerca di equilibri. E tutti da scoprire, giorno per
        giorno, fra gli elementi imponderabili di un lessico che sta fra scienza
        e fantasia. E Desirelli è divenuto un esperto costruttore della propria
        sintassi. Il tempo lungo che egli impiega a tessere la sua grande
        ragnatela è l’unico che per lui valga. Lo passa infatti spiando le
        possibili articolazioni delle sue spirali cristalline e mobili; il ritmo
        di quei moti a vortice che sondano spazi sconosciuti. Il suo lavoro
        acquista sempre maggior scioltezza.
      </p>
      <p>
        Si definisce, inoltre, nei suoi significati sottesi, nelle sue tensioni.
        E in realtà il quadro oggetto di Desirelli ha caratteristiche di
        inquieta mobilità e lucentezza; esse riflettono in se stesse anche dei
        commessi risvolti psicologici. Le strutture schioccano nello spazio come
        intreccio di canapi violenti; disegno e tingono l’aria come fasci di
        curve di moto, di onde di suono; il loro infittirsi e involgersi danno
        il segno di una crescita quasi ossessiva. Desirelli non sterilizza,
        cioè, quel senso di sottile sgomento che ci dà la conoscenza di quelle
        forze motorie presenti e operanti nello spazio.
      </p>
      <p>
        Ciò che egli viene proiettando in figura di irretimento senza fine
        possiede anche il timbro di un travaglio investigativo, di un’ansia di
        verificare fino a che punto si può nobilitare, attraverso il fragile
        laccio degli eventi plastico-ottici, la ridda dei fenomeni.
      </p>
      <p>
        Se le costruzioni aeree, per così dire, della pittura di Desirelli
        possono essere intese nella scia delle esperienze “optical”, il loro
        congegnarsi nervoso sembra cercare una diversa dimensione puntando su
        scontri di strutture, individuando componenti di contrasto, elementi di
        disturbo. Per quanto riguarda, poi, la particolare colorazione dei
        dipinti, variata su pochi azzurri, viola, rosa e bianchi, essa
        costituisce un elemento sostanziane dell’ordito. La sua elaborazione
        raggiunge nei dipinti recenti una virtualità strettamente relativa ad
        una concezione di fluidità non pacifica, di una catena accidentata di
        spinte e controspinte nella materia. E’ un colore che dà una bellezza
        glaciale.
      </p>
      <p>
        Alla fine, il dialogo fra questi fasci di luce che invadono la tela
        sollecitano nel visore l’idea di un viaggio folgorante e ingannevole,
        finemente minaccioso, fra strutture di un mondo di forze che la scienza
        definisce con grafici e equazioni; mentre la pittura, apparentemente
        presa nel clima di una lusinga ipnotica, ricostruisce un’impronta che è
        memoria ed emozione, segno di equilibrio fra materia e spirito.
      </p>
      <p>Elda Fezzi</p>
    </div>
  </Layout>
);

export default EldaFezziPage;
